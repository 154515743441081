<template>
  <div class="edit-invoice">
    <p class="title">编辑发票信息</p>
    <div class="invoice-type">
      <span>发票类型</span>
      <div class="btn-group">
        <span :class="['btn',invoiceIndex === 0?'active':'']" @click="invoiceTypeClick('0')">电子普通发票</span>
        <span :class="['btn',invoiceIndex === 1?'active':'',disabledFlag?'disabled':'']" @click="invoiceTypeClick('1')">增值税专用发票</span>
      </div>
    </div>
    <div class="invoice-content">
      <p class="content-title">发票抬头</p>
      <div class="content-rules">
        <InvoiceCenterRules />
      </div>
      <div class="form-wrap">
      <Form ref="form" v-if="showForm" :model="form" :rules="rules" :label-width="160">
        <div v-show="invoiceIndex === 0">
          <FormItem label="发票抬头:">
            <span :class="{'active':personIndex === 0}" @click="changeType('0')">个人</span>
            <span :class="{'active':personIndex === 1}" @click="changeType('1')">单位</span>
          </FormItem>
          <FormItem v-if="personIndex === 0" label="个人名称:" prop="invoiceTitle">
            <Input v-model.trim="form.invoiceTitle" placeholder="请填写“个人”或您的姓名"></Input>
          </FormItem>
        </div>
        <div v-show="personIndex === 1 || invoiceIndex === 1">
          <FormItem label="公司名称:" prop="invoiceTitle">
            <Input v-model.trim="form.invoiceTitle" @on-blur="handleBlur" placeholder="请输入公司名称"></Input>
          </FormItem>
          <FormItem label="纳税人识别号:" prop="invoiceTaxpayer">
            <Input v-model.trim="form.invoiceTaxpayer" placeholder="请输入纳税人识别号"></Input>
          </FormItem>
          <FormItem label="注册地址:" prop="registeredAddress">
            <Input v-model.trim="form.registeredAddress" :placeholder="`${txtFlag?'必':'选'}填`"></Input>
          </FormItem>
          <FormItem label="公司电话:" prop="registeredTelephone">
            <Input v-model.trim="form.registeredTelephone" :placeholder="`${txtFlag?'必':'选'}填`"></Input>
          </FormItem>
          <FormItem label="开户银行:" prop="bank">
            <Input v-model.trim="form.bank" :placeholder="`${txtFlag?'必':'选'}填`"></Input>
          </FormItem>
          <FormItem label="银行账户:" prop="account">
            <Input v-model.trim="form.account" :placeholder="`${txtFlag?'必':'选'}填`"></Input>
          </FormItem>
        </div>
        <FormItem label="发票内容:">
          <div class="goods-btn">商品明细</div>
          <i class="goods-i">发票内容将显示详细商品名称与价格信息</i>
        </FormItem>
      </Form>
      </div>
      <div class="foot">
        <span class="save" @click="handleSave">保存</span>
        <span class="cancel" @click="handleCancel">取消</span>
      </div>
    </div>
  </div>
</template>
 
<script>
import { mapState } from 'vuex';
import {checkLoginConfig} from '@/utils/common'
import {captchaCheck} from '@/api/api'
import InvoiceCenterRules from './InvoiceCenterRules.vue'
import {editInvoiceCenter,queryTaxCode} from '@/api/api'

export default {
  name: 'InvoiceCenterEdit',
  components:{
    InvoiceCenterRules
  },
  props:{
    valueAddedFlag:Boolean
  },
  mounted() {
    // 网易网盾配置
    checkLoginConfig({
      init: (instance) => {
        // 初始化成功后得到验证实例instance，可以调用实例的方法
        console.log('初始化成功', instance)
        this.captchaIns = instance
      },
      afterCheck: (val) => {
        // 验证成功后，调用close方法关闭弹框（enableClose为true时调用）
        this.captchaIns.close()
        captchaCheck({
          captchaValue: val.validate,
        }).then((res) => {
          if(res.result === 0){
            this.preInvoiceTitle = ''
            this.captchaIns.refresh() 
            this.handleBlur()
          }
        })
      },
    })
  },
  data () {
    return {
      invoiceTitle:'',
      captchaIns:null, //网易网盾插件实例
      showForm:true,
      invoiceIndex:0,
      personIndex:0,
      cacheForm:null,
      preInvoiceTitle:'',
      form:{
        invoiceTitle:'',
        invoiceTaxpayer:'',
        registeredAddress:'',
        registeredTelephone:'',
        bank:'',
        account:''
      },
      ruleValidate:{
        invoiceTitle: [
          { required: true, message: '请填写公司名称', trigger: 'blur' },
          { type: 'string', min: 3, message: '公司名称限3-30个字', trigger: 'blur' },
          { type: 'string', max: 30, message: '公司名称限3-30个字', trigger: 'blur' }
        ],
        invoiceTaxpayer: [
          { required: true, message: '请填写纳税人识别号', trigger: 'blur' },
          { type: 'string', min: 15, message: '纳税人识别号限15-20个字', trigger: 'blur'},
          { type: 'string', max: 20, message: '纳税人识别号限15-20个字', trigger: 'blur' }
        ],
        registeredAddress: [
          { required: true, message: '请填写注册地址', trigger: 'blur' },
          { type: 'string', min: 8, message: '注册地址限8-80个字', trigger: 'blur' },
          { type: 'string', max: 80, message: '注册地址限8-80个字', trigger: 'blur' }
        ],
        registeredTelephone: [
          { required: true, message: '请填写公司电话', trigger: 'blur' },
          { type: 'string', min: 10, message: '公司电话限10-15个字', trigger: 'blur' },
          { type: 'string', max: 15, message: '公司电话限10-15个字', trigger: 'blur' }
        ],
        bank: [
          { required: true, message: '请填写开户银行', trigger: 'blur' },
          { type: 'string', min: 4, message: '开户银行限4-80个字', trigger: 'blur'},
          { type: 'string', max: 80, message: '开户银行限4-80个字', trigger: 'blur'}
        ],
        account: [
          { required: true, message: '请填写银行账户', trigger: 'blur' },
          { type: 'string', min:6, message: '银行账户限6-30个字', trigger: 'blur' },
          { type: 'string', max:30, message: '银行账户限6-30个字', trigger: 'blur' },
        ],
      },
      ruleValidateCompany:{
        invoiceTitle: [
          { required: true, message: `请填写公司名称`, trigger: 'blur' },
          { type: 'string', min: 3, message: '公司名称限3-30个字', trigger: 'blur' },
          { type: 'string', max: 30, message: '公司名称限5-30个字', trigger: 'blur' }
        ],
        invoiceTaxpayer: [
          { required: true, message: '请填写纳税人识别号', trigger: 'blur' },
          { type: 'string', min: 15, message: '纳税人识别号限15-20个字', trigger: 'blur' },
          { type: 'string', max: 20, message: '纳税人识别号限15-20个字', trigger: 'blur' }
        ],
        registeredAddress: [
          { type: 'string', max: 80, message: '注册地址最多80个字', trigger: 'blur' }
        ],
        registeredTelephone: [
          { type: 'string', max: 15, message: '公司电话最多15个字', trigger: 'blur' }
        ],
        bank: [
          { type: 'string', max: 80, message: '开户银行最多80个字', trigger: 'blur' }
        ],
        account: [
          { type: 'string', max: 20, message: '银行账户最多20个字', trigger: 'blur' }
        ],
      },
      ruleValidatePerson:{
        invoiceTitle: [
          { required: true, message: `请填写个人名称`, trigger: 'blur' },
          { type: 'string', min: 2, message: '个人名称限2-30个字', trigger: 'blur'},
          { type: 'string', max: 30, message: '个人名称限2-30个字', trigger: 'blur'}
        ],
      },
    }
  },
  computed: {
    ...mapState(['userInfo']),

    txtFlag(){
      return this.invoiceIndex === 1
    },
    rules(){
      return this.invoiceIndex === 1 ?this.ruleValidate:this.personIndex === 1? this.ruleValidateCompany:this.ruleValidatePerson
    },
    disabledFlag(){
      return this.cacheForm? this.cacheForm.type !== 4 && this.valueAddedFlag :this.valueAddedFlag
    }
  },
  methods: {
    // 普通发票，专用发票切换
    invoiceTypeClick(type){
      if(this.disabledFlag && type === '1'){
        this.$Message.info('最多可保存1条增值税专用发票抬头')
        return
      }
      if(this.invoiceIndex === +type ) return
      this.invoiceIndex = +type
      this.resetFrom()
      this.loadCacheForm()
    },
    // 个人，单位切换
    changeType(type){
      if(this.personIndex === +type) return
      this.personIndex = +type
      this.resetFrom()
      this.loadCacheForm()
    },
    resetFrom(){
      this.form = {
        invoiceTitle:'',
        invoiceTaxpayer:'',
        registeredAddress:'',
        registeredTelephone:'',
        bank:'',
        account:''
      }
      this.showForm = false
      setImmediate(() => {
        this.showForm = true
      })
    },
    loadCacheForm(){
      if(this.cacheForm?.type === 2){
        if(this.personIndex !== 0 || this.invoiceIndex === 1) return
        this.form = Object.assign({},this.cacheForm)
      }else if(this.cacheForm?.type === 3){
        if(this.personIndex !== 1 || this.invoiceIndex === 1) return
        this.form = Object.assign({},this.cacheForm)
      }else if(this.cacheForm?.type === 4){
        if(this.invoiceIndex !== 1) return
        this.form = Object.assign({},this.cacheForm)
      }
    },
    handleSave(){
      this.$refs.form.validate(async (valid) => {
        if (!valid) return
        // 2-个人 3-公司 4-增值税发票
        const obj = {
          0:'2',
          1:'3'
        }
        const data = {
          ...this.form,
          type:this.invoiceIndex === 1?'4':obj[this.personIndex]
        }

        !data.userId && (data.userId = this.userInfo.userId)
        if(!data.id && this.cacheForm){
          data.id = this.cacheForm.id
        }

        let query = {}
        if(this.invoiceIndex === 0 && this.personIndex === 0){
          // 个人
          query.invoiceTitle = data.invoiceTitle
          query.type = data.type
          query.userId = data.userId
          data.id && (query.id = data.id)
        }else{
          query = data
        }
        const {result} = await editInvoiceCenter(query)
        if(result !== 0) return
        this.$Message.success('保存成功')
        this.$parent.getInvoiceCenterListFn()
        setImmediate(() => {
          this.$parent.listFlag = true
        },1000)
      })
    },
    async handleBlur(){
      const str = this.form.invoiceTitle
      if(!str || str.length < 5 || str.length > 30) return
      if(this.preInvoiceTitle === str) {
            //  名称没有改变，不请求
            return
      }
      this.preInvoiceTitle = str
      if(this.form.invoiceTaxpayer.length > 0 || str === '') {
        //  有识别码不请求
        return
      }
      const {result,message,data:{taxCode}} = await queryTaxCode({companyName:str})
      if(result === 0 && taxCode){
        this.$refs.form.fields.find(_ => _._props.prop === 'invoiceTaxpayer').resetField()
        this.form.invoiceTaxpayer = taxCode
      }else if (result === 2231){ // 进行人工校验：图形验证码
          console.log('进行人工校验啊啊啊啊啊22222', this.captchaIns)

          this.captchaIns.popUp();
        }
        else if(result !== 2083){
        this.$Message.error(message)
      }
    },
    handleCancel() {
      this.form.invoiceTitle =this.invoiceTitle
      this.$parent.listFlag = true
   
    }
  }
}
</script>
 
<style lang="less" scoped>
  .edit-invoice{
    padding-top: 10px;
  }
  .title{
    font-weight: bold;
    color: #333;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
    border-bottom: 1px solid #f2f2f2;
  }
  .invoice-type{
    font-size: 18px;
    color: #333;
    padding: 18px 20px;
    border: 1px solid #f2f2f2;
    margin-top: 12px;
    .btn-group{
      padding-top: 20px;
      .btn{
        display: inline-block;
        height: 32px;
        padding: 0 16px;
        text-align: center;
        line-height: 32px;
        border: 1px solid #999;
        margin-left: 25px;
        cursor: pointer;
        color: #999;
        font-size: 14px;
        &.active{
          border-color: #CC0000;
          color: #CC0000;
        }
        &.disabled{
          background-color: grey;
          color: #fff;
          cursor: not-allowed;
        }
      }
    }
  }

  .invoice-content{
    border: 1px solid #f2f2f2;
    margin-top: 12px;
    padding: 18px 20px;
    font-size: 14px;
    color: #333;
    .content-title{
      font-size: 18px;
    }
    .content-rules{
      border: 1px solid #f2f2f2;
      padding:2px 10px 0;
      margin-top: 18px;
      height: 128px;
      overflow-y: scroll;
    }
  }

  /deep/.ivu-form{
    margin-top: 30px;
    color: #333;
    .ivu-form-item-content{
      span{
        display: inline-block;
        width: 100px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        border: 1px solid #f2f2f2;
        cursor: pointer;
        color: #999;
        &:last-child{
          margin-left: 20px;
        }
        &.active{
          color: #CC0000;
          border-color: #CC0000;
        }
      }
    }
    .ivu-form-item-label{
      color: #333;
      padding-right: 24px;
    }
    .ivu-input{
      width: 60%;
      border-radius: 0;
    }
    .goods-btn{
      width: 150px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border: 1px solid #CC0000;
      color: #CC0000;
    }
    .goods-i{
      color: #999;
      font-size: 12px;
    }
  }
  
  .form-wrap{
    min-height: 300px;
  }

  .foot{
    text-align: center;
    span{
      display: inline-block;
      width: 150px;
      height: 46px;
      text-align: center;
      line-height: 46px;
      border-radius: 6px;
      cursor: pointer;
    }
    .save{
      background-color: #CC0000;
      color: #fff;
    }
    .cancel{
      color: #999;
      border: 1px solid #999;
      margin-left: 20px;
    }
  }
</style>