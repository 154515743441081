<template>
  <div class="invoice-wrap">
    <div v-if="listFlag" class="invoice-list">
      <div class="add-btn" @click="addInvoice">新增发票抬头</div>
      <template v-if="flag">
        <div class="section" v-for="(item,index) in invoiceList" :key="index">
          <span class="title">{{item.title}}</span>
          <ul class="li-list">
            <li class="li-item" v-for="ele in item.list" :key="ele.id">
              <div class="content-left">
                <p><span>发票抬头:</span>{{ele.invoiceTitle}}</p>
                <p v-if="ele.type !== 2"><span>纳税人识别号:</span>{{ele.invoiceTaxpayer}}</p>
              </div>
              <div class="content-right">
                <span class="edit" @click="editInvoice(ele)">编辑</span>
                <span class="del" @click="delInvoice(ele,index)">删除</span>
              </div>
            </li>
          </ul>
        </div>
      </template>
      <div v-else class="empty">
        <img src="@/assets/imgs/empty/content.png" alt="">
        <p>暂无发票抬头</p>
      </div>
    </div>
    <InvoiceCenterEdit
      v-else
      ref="invoiceCenterEditRef"
      :valueAddedFlag="valueAddedFlag"
    />
    <Modal v-model="modal" width="500" :closable="false">
      <div slot="header" class="slot-header">
        <img src="@/assets/imgs/cart/confirm-icon.png" alt="">
        <h3>确定要删除此发票抬头吗</h3>
      </div>
      <div class="slot-content">
        <p><span>发票抬头:</span>{{delInvoiceObj.invoiceTitle}}</p>
        <p v-if="delInvoiceObj.type !== 2" style="margin-top:8px"><span>纳税人识别号:</span>{{delInvoiceObj.invoiceTaxpayer}}</p>
      </div>
      <div slot="footer" class="slot-footer">
        <div class="btn confirm" @click="confirmBtn">确定</div>
        <div class="btn cancel" @click="modal = false">取消</div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {getInvoiceCenterList,editInvoiceCenter,delInvoiceCenter} from '@/api/api'
import InvoiceCenterEdit from './InvoiceCenterEdit.vue'

export default {
  name: 'InvoiceCenter',
  components:{
    InvoiceCenterEdit
  },
  data () {
    return {
      invoiceList:[], //发票列表
      flag:true,
      delInvoiceObj:{}, //删除发票
      modal:false, // 模态框
      listFlag:true,
      valueAddedFlag:false
    }
  },
  computed: {
    ...mapState(['userInfo']),
  },
  created () {
    this.getInvoiceCenterListFn()
  },
  watch:{
    invoiceList(val){
      val.length <=0 && (this.flag = false)
      const valueAddedArr = val.find(item => item.groupName === 'valueAdded')
      this.valueAddedFlag = valueAddedArr && valueAddedArr.list.length >= 1
    }
  },
  methods: {
    async getInvoiceCenterListFn(){
      const {result,data} =  await getInvoiceCenterList({userId:this.userInfo.userId})
      if(result !== 0){
        this.flag = false
        return
      }
      this.initList(data)
    },
    
    initList(data){
      const titleObj = {
        valueAdded:'增值税专用发票抬头',
        personal:'普通发票抬头-个人',
        company:'普通发票抬头-公司'
      }
      this.invoiceList = []
      for (const key in titleObj) {
        if(data[key].length <= 0 ) continue
        const obj = {}
        obj.title = titleObj[key]
        obj.groupName = key
        obj.list = data[key]
        this.invoiceList.push(obj)
      }
      this.invoiceList.length <= 0 && (this.flag = false)
    },
    // 新增发票
    addInvoice(){
      this.listFlag = false
      this.$nextTick(() => {
        this.$refs.invoiceCenterEditRef.cacheForm && (this.$refs.invoiceCenterEditRef.cacheForm = null)
      })
    },
    // 编辑发票抬头
    editInvoice(ele){
      this.listFlag = false
      this.$nextTick(() => {
        ele.type === 4 && (this.$refs.invoiceCenterEditRef.invoiceIndex = 1)
        ele.type === 3 && (this.$refs.invoiceCenterEditRef.personIndex = 1)
        this.$refs.invoiceCenterEditRef.form = ele
        this.$refs.invoiceCenterEditRef.invoiceTitle = ele.invoiceTitle
        this.$refs.invoiceCenterEditRef.preInvoiceTitle = ele.invoiceTitle || ''
        this.$refs.invoiceCenterEditRef.cacheForm = ele
      })
    },

    // 删除发票抬头
    delInvoice(ele,index){
      this.catchIndex = index
      this.modal = true
      if(ele.id === this.delInvoiceObj.id) return
      this.delInvoiceObj = ele
    },
    
    // 删除-确认
    async confirmBtn(){
      const {result} =   await delInvoiceCenter({id:this.delInvoiceObj.id})
      if(result !== 0) return
      this.$Message.success('删除成功')
      if(this.invoiceList[this.catchIndex].list.length === 1){
        this.invoiceList.splice(this.catchIndex,1)
      }else{
        const i = this.invoiceList[this.catchIndex].list.findIndex(item => item.id === this.delInvoiceObj.id)
        this.invoiceList[this.catchIndex].list.splice(i,1)
      }
      this.modal = false
    }
  }
}
</script>
 
<style lang="less" scoped>
  .invoice-wrap{
    background-color: #fff;
    padding: 0 48px 45px 48px;
  }
  .invoice-list{
    padding-top: 32px;
  }
  .add-btn{
    width: 144px;
    height: 44px;
    text-align:center;
    line-height: 44px;
    color: #EF2F2F;
    background: rgba(239,47,47,0.05);
    border: 1px solid #ef2f2f;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  }

  .section{
    .title{
      display: inline-block;
      color: #000;
      font-weight: bold;
      margin: 32px 0 16px;
    }
    .li-list{
      padding: 0 24px;
      border: 1px solid rgb(242, 242, 242);
      .li-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid rgb(242, 242, 242);
        padding: 16px 0;
        &:first-child{
          border-top: none;
        }
        .content-left{
          color: #333;
          p{
            line-height: 1.5;
          }
          span{
            display: inline-block;
            width: 130px;
            color: #999;
          }
        }
        .content-right{
          span{
            cursor: pointer;
          }
          .edit{
            color: #0085FF;
          }
          .del{
            color: #CC0000;
            margin-left: 24px;
          }
        }
      }
    }
  }

  .empty{
    width: 100%;
    padding:100px ;
    text-align: center;
    img{
      width: 200px;
      height: 200px;
    }
  }

  /deep/.ivu-modal-content{
    border-radius: 0;
    .ivu-modal-header{
      padding-top: 24px;
      border-bottom: none;
      .slot-header{
        text-align: center;
        img{
          width: 40px;
          height: 40px;
        }
        h3{
          font-weight: bold;
          color: #333;
          font-size: 18px;
          margin-top: 16px;
        }
      }

    }
    .ivu-modal-body{
      padding-top: 25px;
      .slot-content{
        color: #333;
        span{
          width: 96px;
          color: #999;
          display: inline-block;
          text-align: right;
          margin-right: 10px;
        }
      }
    }
    
    .ivu-modal-footer{
      border-top: none;
      padding-bottom: 24px;
      .slot-footer{
        display: flex;
        justify-content: center;
      }
      .btn{
        width: 132px;
        height: 46px;
        text-align: center;
        line-height: 46px;
        border-radius: 4px;
        cursor: pointer;
      }
      .confirm{
        background-color: #CC0000;
        color: #fff;
        margin-right: 24px;
      }
      .cancel{
        border: 1px solid #CC0000;
        color: #999;
      }
    }
  }
</style>