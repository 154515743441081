<template>
  <div class="rules">
    <p>发票须知</p>
    <p v-for="(item,index) in rules" :key="index">
      {{item.title}}
      <span v-for="(ele,i) in item.child" :key="i">
        {{ele.childTitle}}
      </span>
    </p>
  </div>
</template>
 
<script>
 
export default {
  name: 'InvoiceCenterRules',
  components:{
    
  },
  props:{
    
  },
  data () {
    return {
      rules:[
        {
          title:'一、发票金额不含G豆、满减、优惠券、银行支付有礼、格力明珠卡等优惠扣减金额',
          child:[]
        },
        {
          title:'二、电子普通发票',
          child:[
            {
              childTitle:'1.电子普通发票是税局认可的有效收付款凭证，其法律效力、基本用途及使用规定同纸质发票，如需纸质发票可自行下载打印；'
            },
            {
              childTitle:'2.格力自营店铺全面启用电子普通发票，用户可点击（个人中心-全部订单-查看详情-下载发票）查询和下载；'
            },
            {
              childTitle:'3.电子普通发票开票时限：不含优惠订单自货物签收完成后的3个工作日内开具，使用优惠订单7-15个工作日开具。'
            }
          ]
        },
        {
          title:'三、第三方卖家销售的商品/服务的发票由卖家自行出具、提供，发票类型和内容由卖家根据实际商品、服务情况决定。',
          child:[]
        },
        {
          title:'四、增值税专用发票',
          child:[
            {
              childTitle:'1.增值税专用发票资质需要在（个人中心-发票资质）进行操作修改；'
            },
            {
              childTitle:'2.有效增值税专用发票开票资质仅为一个；'
            },
            {
              childTitle:'3.请按照税法规定使用发票；'
            },
            {
              childTitle:'4.增值税专用发票开票时限：货物签收后的7-15个工作日内开具并按预留地址寄出。'
            },
          ]
        },
        {
          title:'五、发票补开换开及修改规则',
          child:[
            {
              childTitle:'1.发票开具成功后，以订单日期为基础，1年内可支持一次发票换开。超过1年则不支持发票换开。'
            },
            {
              childTitle:' 2.更换增值税专用发票，需将原发票寄回格力电子商务公司。邮寄地址为：广东省珠海市香洲区金鸡西路珠海格力岐关办公室（红塔仁恒对面）格力电子商务有限公司财务部0756-8572102。'
            },
          ]
        },
      ]
    }
  },
  
}
</script>
 
<style lang="less" scoped>
  .rules{
    line-height: 1.5;
    span{
      display: block;
    }
  }
</style>